// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

import ArrowLeft from '../components/svg/arrow-left'
import ArrowRight from '../components/svg/arrow-right'

import website from '../seo/website.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Buy Or Contribute',
  slug: '/buy-or-contribute',
  abstract:
    "The author and the publisher of this book have chosen to offer this book, in it's entirety, to read online as a web-book. This is not for free, but available on a Gift-Economy and Pay-What-Feels-Right basis. We want you to enjoy the book, learn from the book, but also support the book and this format of open publishing. If it was worth your time, please consider contributing or buying the print version of this book.",
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Buy Or Contribute', slug: '/buy-or-contribute' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "book/img1.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img2: file(relativePath: { eq: "book/img2.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img3: file(relativePath: { eq: "book/img3.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img4: file(relativePath: { eq: "book/img4.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img5: file(relativePath: { eq: "book/img5.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img6: file(relativePath: { eq: "book/img6.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img7: file(relativePath: { eq: "book/img7.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => {
  const resources = [
    { img: props.data.img1 },
    { img: props.data.img2 },
    { img: props.data.img3 },
    { img: props.data.img4 },
    { img: props.data.img5 },
    { img: props.data.img6 },
    { img: props.data.img7 },
  ]

  const sliderConf = {
    infinite: false,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    customPaging: (i) => {
      return (
        <a aria-label={`Go to slide ${i + 1}`} role="link">
          <GatsbyImage image={getImage(resources[i].img)} />
        </a>
      )
    },
    dotPosition: 'bottom',
  }

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <AboutPageSchema />
      <ContactPageSchema />
      <div className="container">
        <h1>Support open publishing</h1>
        <p>
          The author and the publisher of this book have chosen to offer this
          book to read online as a web-book. This is not for free, but available
          on a Gift-Economy and Pay-What-Feels-Right basis. We want you to enjoy
          the book, learn from the book, but also support the book, the author,
          and this format of open publishing. If it was worth your time, please
          consider contributing or buying the print version of this book.
        </p>
        <h2>Get the print version of the book</h2>
        <p>
          Over 130 poems are included in the print version of the book. You can
          get a copy from{' '}
          <Link to="https://goo.gl/maps/6MApbqD6vadWFQ387">
            Auroville Papers Bookstore
          </Link>
          ,{' '}
          <Link to="https://goo.gl/maps/PBQiqBDaFSX93Tt26">
            Auroville Information Service at Visitor’s Centre
          </Link>
          , and{' '}
          <Link to="https://goo.gl/maps/SRTJSMJuxboWCAQo6">
            Freeland Bookstore
          </Link>
          .
        </p>
        <h2>Buy the print version of the book online</h2>
        <p>
          Please follow{' '}
          <Link to={website.shopUrl}>
            <strong style={{ textDecoration: 'underline', fontWeight: 700 }}>
              this link
            </strong>
          </Link>{' '}
          to buy the print version of this book online.
        </p>
        <h2>Contribute directly to the author</h2>
        <p>
          Please write to Anandi Zhang (ourhome@auroville.org.in) a letter
          indicating your intent to contribute and she will share further
          instructions.
        </p>
      </div>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
